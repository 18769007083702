/* Stanford Law School: SIDDLAPP CSS - 12-07-2022 */

html {
  background: #f2efea;
  height: 100%;
  overflow-x: hidden;
}

body, button, form, input, select, textarea {
font: 400 normal 100%/1.55 proxima-nova,"Helvetica Neue",Helvetica,Arial,sans-serif;
color: #1a1a1a;
background: #f2efea;
overflow-x: hidden;
}

.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 1rem 1.5rem;
  background-color: #b30000;
  color: #fff;
  margin-bottom: 1rem;
}

@media (max-width: 768px){
.panel {
    margin-bottom: 8px;
}
.panel-type-mashup .panel-mashup, .panel-type-query .panel-mashup {
  margin-bottom: 2px;
}
}
@media (min-width: 768px){
.panel {
    margin-bottom: 20px;
}
}

.panel .mu-layout-eight-up.mu-wrap-inner {
  margin-bottom: 0px;
}

@media (min-width: 960px){
.cw-pad, .cw-pad-bottom {
    padding-bottom: 50px;
}
}
.cw-pad, .cw-pad-bottom {
    padding-bottom: 10px;
}
@media (min-width: 960px){
.cw-pad, .cw-pad-top {
    padding-top: 60px;
}
}
.cw-pad, .cw-pad-top {
    padding-top: 30px;
}

.context-content p {
  margin-bottom: 0px;
}

.mi-wrap {
padding-top: 10px;
}

.mi-wrap.mi-has-no-image, .mi-wrap.mi-layout-overlay {
  height: 0px;
}
.bgd-white{
  background-color: #ffffff;
}

@media (min-width:768px){.panel:last-child{margin-bottom:26px}}
@media (min-width: 960px){.panel:last-child{margin-bottom:26px;}}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.5rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem;
}

.ais-Highlight-highlighted {
  color: inherit;
  font-size: inherit;
}

#searchbox {
  margin-bottom: 2rem;
}

#pagination {
  margin: 2rem auto;
  text-align: center;
}

#hits {
  margin-top: 1rem;
}

.search-panel__filters {
  display: flex;
}

.search-panel__filters > div {
  margin-right: 0.5rem;
}

.search-panel__filters > div:last-child {
  margin-right: 0.5rem;
}

.ais-HierarchicalMenu-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-RatingMenu-list,
.ais-RefinementList-list {
  font-weight: normal;
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
  font-weight: bold;
}

/* Custom styles for the dropdown widgets  */

#category .ais-HierarchicalMenu {
  height: 195px;
  overflow: auto;
}

#price .ais-RangeSlider {
  width: 12rem;
}

#price2 .ais-NumericMenu-list {
  width: 7rem;
  display: flex;
  flex-wrap: wrap;
}

